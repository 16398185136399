<template>
   <UPopover mode="hover"> 
    <UButton square color="gray" :icon="icon" size="md" :ui="ui" />
    <template #panel>
      <p 
        class="p-3 max-w-xs sm:max-w-md text-2xs leading-loose font-normal text-left"
      >
       <span v-html="text"></span>
      </p>
    </template>
  </UPopover>
  
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  icon?: string
  text: string,
  btnClass?: string
}>(), {
  icon: 'i-heroicons-information-circle-20-solid'
})

const ui = { 
  rounded: 'rounded-full', 
  color: { 
    gray: { 
      solid: props.btnClass ?? 'ring-gray-200 text-gray-500 dark:bg-gray-700 dark:ring-gray-600 dark:text-gray-300'
    }
  } 
}
</script>